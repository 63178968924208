import * as React from "react";

import { Box, Divider } from "@mui/material";
import {
  CAO_DASHBOARD_AUTHZ_GROUPS,
  POOLSIDE_AUTHZ_GROUPS,
} from "../constants/roles";
import { hasAnyGroup } from "../utils/groups";
import {
  INavigationPanelSectionLinkProps,
  NavigationPanelAvatar,
  NavigationPanelFooter,
  NavigationPanelFooterLink,
  NavigationPanel as NavigationPanelRoot,
  NavigationPanelSection,
  NavigationPanelSectionAnchor,
  NavigationPanelSectionLink,
} from "./navigation-panel";

import { Location, useLocation } from "react-router";
import { ProfileContext } from "../providers/ProfileProvider";
import signInUrl from "../signInUrl";

// The project's source code repository (used in the navbar's footer)
const PROJECT_REPO =
  "https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/pools/smi";

// The URL for the feedback form
const FEEDBACK_FORM =
  "https://forms.office.com/Pages/ResponsePage.aspx?id=RQSlSfq9eUut41R7TzmG6Y5KG8YYTZNAhCXR5rPAUvJUODhEQVdYQzhYWVNCRTdIVExQU0daMDFZTC4u";

/**
 * Utility function to return props for ListItem when it is used as a Link to other parts of the
 * application.
 */
const navLinkProps = (
  location: Location,
  pathname: string,
  text: string,
): INavigationPanelSectionLinkProps => {
  const selected = pathname === location.pathname;
  return {
    // retains GlobalFilter state between pages by retaining search/query string parameters
    to: { pathname: pathname, search: location.search },
    text,
    selected,
    dataRole: "link",
  };
};

/**
 * Content of the side navigation drawer.
 */
export const NavigationPanel = () => {
  const profile = React.useContext(ProfileContext);
  const location = useLocation();
  const sections = [];

  if (profile === null) {
    // No profile yet
  } else if (profile.isAnonymous) {
    sections.push(
      <NavigationPanelSection dataRole="accountDrawer">
        <NavigationPanelSectionAnchor
          link={signInUrl("/")}
          text="Sign in"
          dataRole="signIn"
        />
      </NavigationPanelSection>,
    );
  } else {
    sections.push(
      <NavigationPanelAvatar profile={profile} />,
      <NavigationPanelSection dataRole="navDrawer">
        <NavigationPanelSectionLink
          {...navLinkProps(location, "/summary", "Summary")}
        />
        <NavigationPanelSectionLink
          {...navLinkProps(location, "/applications", "Applications")}
        />
        <NavigationPanelSectionLink
          {...navLinkProps(location, "/charts", "Charts")}
        />
        {hasAnyGroup(profile, POOLSIDE_AUTHZ_GROUPS) && (
          <NavigationPanelSectionLink
            {...navLinkProps(
              location,
              "/poolsideMeetings",
              "Poolside meetings",
            )}
          />
        )}
        {profile.invitableRoles.length > 0 && (
          <NavigationPanelSectionLink
            {...navLinkProps(location, "/invitations", "Invitations")}
          />
        )}
        {hasAnyGroup(profile, CAO_DASHBOARD_AUTHZ_GROUPS) && (
          <NavigationPanelSectionLink
            {...navLinkProps(location, "/caoDashboard", "CAO Dashboard")}
          />
        )}
      </NavigationPanelSection>,
      <NavigationPanelSection dataRole="accountDrawer">
        <NavigationPanelSectionAnchor
          link="/accounts/logout"
          text="Sign out"
          dataRole="signOut"
        />
      </NavigationPanelSection>,
    );
  }

  return (
    <NavigationPanelRoot>
      <Box flexGrow={1}>
        {sections.map((section, index) => (
          <React.Fragment key={index}>
            {index !== 0 ? <Divider /> : null}
            {section}
          </React.Fragment>
        ))}
      </Box>
      <Divider />
      <NavigationPanelFooter>
        <NavigationPanelFooterLink
          link={PROJECT_REPO + "/wikis/About"}
          text="About"
        />
        <NavigationPanelFooterLink
          link={PROJECT_REPO + "/wikis/Terms-of-Service"}
          text="Terms of Service"
        />
        <NavigationPanelFooterLink link={PROJECT_REPO} text="Developers" />
        <NavigationPanelFooterLink link={FEEDBACK_FORM} text="Feedback" />
      </NavigationPanelFooter>
    </NavigationPanelRoot>
  );
};

export default NavigationPanel;
