import * as React from "react";

import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import { drawerWidth } from "theme";

interface StyledMuiAppBarProps extends AppBarProps {
  open: boolean;
  hideDrawer?: boolean;
}

// We support all the AppBar properties as well as an "onMenuClick" event handler.
export interface IProps extends StyledMuiAppBarProps {
  /** Event handler for click on menu "hamburger" button. */
  onMenuClick?: React.MouseEventHandler;
}

const StyledMuiAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<StyledMuiAppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

/**
 * An application bar which shows the current document's title. For small screens, there is a
 * "hamburger" menu button displayed. If the component has any children, they are rendered within
 * the central Toolbar component in place of the document title.
 */
export const AppBar = ({
  children,
  onMenuClick,
  open,
  hideDrawer = false,
  ...otherProps
}: IProps) => {
  return React.createElement(
    hideDrawer ? MuiAppBar : StyledMuiAppBar,
    {
      open,
      ...otherProps,
    },
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={onMenuClick}
        edge="start"
        size="large"
        sx={{ mr: 2, ...((open || hideDrawer) && { display: "none" }) }}
      >
        <MenuIcon />
      </IconButton>
      {children || (
        <Typography variant="h6" color="inherit">
          {document.title}
        </Typography>
      )}
    </Toolbar>,
  );
};

export default AppBar;
