import React from "react";

import NoPermissionsPage from "../pages/NoPermissionsPage";
import { ProfileContext } from "../providers/ProfileProvider";
import { hasAnyGroup } from "../utils/groups";

type Props = {
  /** The user is allowed to access the route if they are a member of one or
   * more of the specified groups. */
  anyOneOfGroups: Set<string>;
};

const PermissionedRoute = ({
  anyOneOfGroups,
  children,
}: React.PropsWithChildren<Props>) => {
  const profile = React.useContext(ProfileContext);
  const isAllowed = hasAnyGroup(profile, anyOneOfGroups);

  return profile && isAllowed ? <>{children}</> : <NoPermissionsPage />;
};

export default PermissionedRoute;
