import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { CssBaseline, Divider, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import BannerMessages from "components/BannerMessages";
import * as React from "react";
import { Navigate } from "react-router";
import { useBannerMessageList } from "../apiHooks";
import AppBar from "../components/AppBar";
import LoadingIndicator from "../components/LoadingIndicator";
import NavigationPanel from "../components/NavigationPanel";
import LogoImage from "../images/logo.svg";
import { ProfileContext } from "../providers/ProfileProvider";
import signInUrl from "../signInUrl";
import { drawerWidth } from "../theme";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  minWidth: 0, // Important to prevent to grow bigger than the parent
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  backgroundColor: theme.palette.action.hover,
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

const LogoWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  display: "flex",
}));

const Wrapper = styled(Box)(() => ({
  display: "flex",
  minHeight: "100vh",
}));

export interface IPageProps {
  /** Children of the top-level AppBar content. */
  appBarChildren?: React.ReactNode;
  /** Page can be viewed by anonymous user */
  allowAnonymous?: boolean;
  /** Hide the navigation drawer */
  hideDrawer?: boolean;
}

/**
 * A top level component that wraps all pages to give then elements common to all page,
 * the ``AppBar`` etc.
 */
const Page: React.FunctionComponent<React.PropsWithChildren<IPageProps>> = ({
  children,
  appBarChildren,
  allowAnonymous = false,
  hideDrawer = false,
}) => {
  const [open, setOpen] = React.useState(true);

  const profile = React.useContext(ProfileContext);

  const {
    response: { results: bannerMessages = [] } = {},
  } = useBannerMessageList();

  // anonymous users not permitted so redirect them the sign in url
  if (!allowAnonymous && profile && profile.isAnonymous) {
    return <Navigate to={signInUrl()} />;
  }

  const handleDrawerToggle = () => setOpen((prev) => !prev);

  return (
    <Wrapper>
      <CssBaseline />
      <AppBar
        position="fixed"
        onMenuClick={handleDrawerToggle}
        open={open}
        hideDrawer={hideDrawer}
      >
        {appBarChildren}
      </AppBar>
      {hideDrawer ? null : (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          open={open}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerHeader>
            <LogoWrapper>
              <img
                src={LogoImage}
                height="36"
                width="36"
                alt="Subject Moderation"
              />
            </LogoWrapper>
            <IconButton onClick={handleDrawerToggle}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <NavigationPanel />
        </Drawer>
      )}
      <Main open={open}>
        <DrawerHeader />

        <BannerMessages messages={bannerMessages} />

        {!profile ? <LoadingIndicator /> : children}
      </Main>
    </Wrapper>
  );
};

export default Page;
