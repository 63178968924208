import { Box, Link, Paper, Typography } from "@mui/material";
import Page from "../containers/Page";

const GenericErrorPage = () => (
  <Page allowAnonymous hideDrawer>
    <Paper>
      <Box p={8}>
        <Typography variant="h6" paragraph>
          There is a problem with the Subject Moderation Interface
        </Typography>
        <Typography variant="body1" paragraph>
          It may help if you refresh your web browser, or try returning to the
          service later.
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions, contact{" "}
          <Link href="mailto:pools@admin.cam.ac.uk" underline="always">
            pools@admin.cam.ac.uk
          </Link>
          .
        </Typography>
      </Box>
    </Paper>
  </Page>
);

export default GenericErrorPage;
