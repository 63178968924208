import { Box, Link, Paper, Typography } from "@mui/material";
import Page from "../containers/Page";

const NoPermissionsPage = () => (
  <Page allowAnonymous>
    <Paper>
      <Box p={8}>
        <Typography variant="h6" paragraph>
          You don&apos;t have access to this page
        </Typography>
        <Typography variant="body1" paragraph>
          This could be because you don&apos;t have the right permissions, or
          you need to request access.
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions, contact{" "}
          <Link href="mailto:pools@admin.cam.ac.uk" underline="always">
            pools@admin.cam.ac.uk
          </Link>
          .
        </Typography>
      </Box>
    </Paper>
  </Page>
);

export default NoPermissionsPage;
